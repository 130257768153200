import Swiper, { Navigation } from 'swiper';

export default function() {
    var sliders = document.querySelectorAll('.swiper.swiper-slider');

    sliders.forEach(function(slider) {
        var swiper = new Swiper(slider, {
            autoHeight: true,
            slidesPerView: 1, 
            slidesPerGroup: 1, 
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    });

    var carousels = document.querySelectorAll('.swiper.swiper-carousel');

    carousels.forEach(function(carousel) {
        var swiper = new Swiper(carousel, {
            autoHeight: false,
            slidesPerView: 1, 
            slidesPerGroup: 1, 
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                320: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 4,
                },
              }
        });
    });
}