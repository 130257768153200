import axios from 'axios';

function addErrorDivs(form)
{
    var fields = form.querySelectorAll('.form__field');
    fields.forEach(function(field) {
        field.insertAdjacentHTML('beforeend', `<div class="form__error"></div>`);
    });
}

function removeFormErrors(form)
{
    var errors = form.querySelectorAll('.form__error');
    errors.forEach(function(error) {
        error.innerHTML = '';
    }); 
}

function addErrorToField(field, message)
{
    var error = field.querySelector('.form__error');
    error.innerHTML = '<span>' + message + '</span>';
}

export default function()
{
    var forms = document.querySelectorAll('form');
    
    forms.forEach(function(form){
        
        addErrorDivs(form);

        form.addEventListener('submit', function(event){
            event.preventDefault();

            removeFormErrors(form);

            var data = new FormData(form);

            axios.post('/wp-admin/admin-ajax.php', data)
                .then(function (response) {
                    form.insertAdjacentHTML('afterend', '<p>'+response.data.data+'</p>');
                    form.style.display = 'none';
                    var gtagEventName = form.dataset.gtagEvent;
                    if (gtagEventName) {
                        gtag('event', gtagEventName);
                    }
                })
                .catch(function (errors) {
                    if (errors) {
                        errors.response.data.data.forEach(function(error) {
                            var input = form.querySelector(`[name=${error.name}]`);
                            var field = input.closest('.form__field');
                            addErrorToField(field, error.message);
                        });
                    }
                });

        });
    });
}