import domReady from '@roots/sage/client/dom-ready';

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);

import accordions from './accordions.js';
import forms from './forms.js';
import slider from './slider.js';
import nav from './nav.js';

nav();
accordions();
forms();
slider();