import { hide, show, toggle } from 'slidetoggle';

export default function()
{
    var accordions = document.querySelectorAll('.accordion');

    accordions.forEach(function(accordion) {
        var heading = accordion.querySelector('.accordion__heading');
        var content = accordion.querySelector('.accordion__content');

        heading.addEventListener('click', function(event){
            toggle(content, {
                miliseconds: 200,
                transitionFunction: 'ease-in-out',
            });

            accordion.classList.toggle('accordion--open');
        });
    });

}